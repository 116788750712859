<template>
  <div class="data-cont">
    <div class="box shadow form">
      <div class="form-group line">
        <div class="label">手机号：</div>
        <div class="cont">
          <cube-input :clearable="false" v-model.trim="form.strcontactnum" ></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label">证件号：</div>
        <div class="cont">
          <cube-input :clearable="false" v-model.trim="form.strcardid" ></cube-input>
        </div>
      </div>
    </div>
    


    

    <div class="btns">
      <cube-button class="btn" @click="saveData">查询</cube-button>
    </div>


  </div>
</template>

<script>
export default {
  name: 'imeiSearch',
  data () {
    return {
      form: {
        strcardid: '', // 证件号
        strcontactnum: '', // 手机号
      }
    }
  },
  mounted () {
    
  },
  methods: {
    /**
     * 保存数据
     */
    saveData () {
      const vm = this;
      let postData = {...this.form}
      console.log(postData)
      if(! this.form.strcontactnum){
        this.$createToast({
          txt: '请输入手机号',
          type: 'warn'
        }).show()
        return
      }else if(! this.$utils.isPhone(postData.strcontactnum)){
        this.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }else if(! this.form.strcardid){
        this.$createToast({
          txt: '请输入证件号',
          type: 'warn'
        }).show()
        return
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/imei/showImei", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.list && response.data.body.list.length > 0){
              vm.goNext(postData)
            }else{
              vm.$createToast({
                txt: '未查询到服务信息',
                type: 'warn'
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 下一步
     */
    goNext (obj) {
      this.$router.push({
        path: '/imei/contract',
        query: obj
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.data-cont{
  padding: 30px 0 200px 0;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 150px;
    }
    .cont{
      flex: 10;
      text-align: right;
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
  }
}
.on-text{
  color: #208170;
  line-height: 90px;
}
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #208170;
    border-color: #208170;
  }
}
.form-group{
  .check-box:nth-child(3n){
    margin-right: 0;
  }
}
.textarea-out{
  padding-bottom: 30px;
}
.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  position: absolute;
  bottom: 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
</style>